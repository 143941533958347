.container .groups {
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;
  }

  .group-name-editor {
    display: flex;
    align-items: center;
  }

  .user-list-container {
    display: flex;
    flex-direction: row;
    div.card {
      flex: 1;
    }

    .user-list-separator {
      width: 10px;
      height: 10px;
    }
  }

  .group-users-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media (max-width:535px) {
    .user-list-container {
      flex-direction: column;
    }
  }

}
