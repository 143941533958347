.upload-user {
  display: flex;
  justify-content: center;

  &__content {
    width: 65%;
    margin: 3rem 0;
    background-color: #fff !important;
    border-radius: 4px;
    box-shadow: 0 2px 2px #aaa;

    @media(max-width: 1600px) {
      width: 80%;
    }

    @media(max-width: 1350px) {
      width: 90%;
    }

    @media(max-width: 1500px) {
      width: 100%;
    }
  }

  &__image {
    margin: 0 20px;

    @media(max-width: 500px) {

    }

    img {
      width: 100%;
    }

    i {
      width: 100%;
      height: 250px;
      display: block;
      background-size: 14%;
      background-position: center;
      background-repeat: no-repeat;

      &.generic {
        background-image: url(../../../assets/images/icon-generic-type.png);
      }
      &.png {
        background-image: url(../../../assets/images/icon-png.png);
      }
      &.pdf {
        background-image: url(../../../assets/images/icon-pdf.png);
      }
      &.jpg {
        background-image: url(../../../assets/images/icon-jpg.png);
      }
      &.jpeg {
        background-image: url(../../../assets/images/icon-jpeg.png);
      }
      &.doc, &.docx {
        background-image: url(../../../assets/images/icon-doc.png);
      }
      &.xls, &.xlsx {
        background-image: url(../../../assets/images/icon-xls.png);
      }
      &.ppt, &.pptx {
        background-image: url(../../../assets/images/icon-ppt.png);
      }
      &.mp4 {
        background-image: url(../../../assets/images/icon-mp4.png);
      }
      &.mp3 {
        background-image: url(../../../assets/images/icon-mp3.png);
      }
    }
  }

  &__author {
    margin: 30px 20px;

    label {
      display: block;
    }

    input {
      width: 100%;
      font-size: 24px;
      padding: 10px;
    }
  }

  &__header {
    margin: 30px 20px;

    input {
      width: 100%;
      font-size: 24px;
      padding: 10px;
    }
  }

  &__info {
    margin: 30px 20px;

    textarea {
      width: 100%;
      padding: 10px;
      height: 220px;
      margin: 10px 0 0;
    }

    p {
      margin: 10px 0 0;
    }
  }

  &__file {
    margin: 30px 20px;

    p {
      margin: 0 0 16px;
    }

    a {
      width: max-content;
      color: #fff !important;
      padding: 12px 20px;
      border-radius: 4px;
      font-size: 14px;
      display: flex;
      align-items: center;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        color: #fff;
        opacity: 0.9;
        text-decoration: none;
      }

      img,svg {
        margin-right: 10px;
      }
    }
  }

  &__footer {
    margin: 50px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 1150px) {
      align-items: initial;
      flex-direction: column;
    }

    > div {

      @media(max-width: 1150px) {
        margin-bottom: 30px;
      }

      label {
        margin-right: 10px;
      }

      select {
        padding: 10px 20px;

        &:nth-child(2) {
          margin-right: 30px;
        }
      }
    }
  }

  button {
    font-size: 14px;
    border: 0;
    padding: 12px 20px;
    color: #fff;
    border-radius: 4px;
    transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 0.9;
        text-decoration: none;
      }
  }
}
