.upload-list {
  margin: 100px 15px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;

  &__filters {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    border-radius: 4px;
    background: white;
    padding: 24px 32px;
    border: 1px solid #eee;

    label {
      font-size: 18px;
      font-weight: 700;
      margin-right: 10px;
    }

    select {
      width: max-content;
      font-size: 14px;
      padding: 6px 14px;
    }
  }

  &__header {
    height: 54px;
    padding: 0 16px;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    display: grid;
    grid-template-columns: 100px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    span {
      color: #fff;
      white-space: nowrap;
      line-height: 54px;
    }
  }

  &__content {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001f;

    @media(max-width: 1000px) {
      width: 85%;
    }

    @media(max-width: 768px) {
      width: 100%;
      padding: 15px;
      grid-template-columns: 1fr;
    }
  }

  &__item {
    height: 160px;
    padding: 16px;
    list-style: none;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;

    display: grid;
    align-items: center;
    grid-template-columns: 100px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    &--status {
    }

    &--edit {
      span {
        padding: 10px;
        color: #fff;
        font-size: 13px;
        border-radius: 4px;
        width: max-content;
      }
    }

    &--description {
      margin: 0 20px;

      p {
        text-align: left;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-height: 94px;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }

    &--section {
      margin: 0 20px;

      p {
        text-align: center;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-height: 94px;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }

    &--author {
      h3 {
        font-size: 15px;
      }
    }

    &--title {
      text-align:center;
      margin: 0 20px;

      h3 {
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-height: 48px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &--image {
      width: 100px;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      i {
        width: 100%;
        height: 100%;
        display: block;
        background-size: 45%;
        background-position: center;
        background-repeat: no-repeat;

        &.generic {
          background-image: url(../../../assets/images/icon-generic-type.png);
        }
        &.png {
          background-image: url(../../../assets/images/icon-png.png);
        }
        &.pdf {
          background-image: url(../../../assets/images/icon-pdf.png);
        }
        &.jpg {
          background-image: url(../../../assets/images/icon-jpg.png);
        }
        &.jpeg {
          background-image: url(../../../assets/images/icon-jpeg.png);
        }
        &.doc, &.docx {
          background-image: url(../../../assets/images/icon-doc.png);
        }
        &.xls, &.xlsx {
          background-image: url(../../../assets/images/icon-xls.png);
        }
        &.ppt, &.pptx {
          background-image: url(../../../assets/images/icon-ppt.png);
        }
        &.mp4 {
          background-image: url(../../../assets/images/icon-mp4.png);
        }
        &.mp3 {
          background-image: url(../../../assets/images/icon-mp3.png);
        }
      }
    }
  }
}
