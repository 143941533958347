.FormFieldWrapper{ //div
    position: relative;
    textarea {
        min-height: 150px;
    }
    input[type="color"] {
        padding-left: 56px;
    }
  width: 100%
}
.FormFieldLabel{ // label
    margin-bottom: 0;
}

.FormFieldLabelText{ //span
    color: #E5E5E5;
    height: 57px;
    position: absolute;
    top: 0;
    left: 16px;

    display: flex;
    align-items: center;

    transform-origin: 0% 0%;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;

    transition: .1s ease-in-out;
}

.FormFieldInput{
    background: linear-gradient(#fff, #f2f4f7);
    border: 1px solid #ced0da;
    color: #354052;
    height: 36px;
    display: block;
    width: 100%;
    outline: 0;
    padding-left: 15px;
    resize: none;
    font-size: 14px;
    &::-webkit-calendar-picker-indicator {
        opacity: 100;
    }
}
