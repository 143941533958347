.navbar:before, .navbar:after{
  display: none;
}

.react-datepicker-component .react-datepicker-input{
  border-radius: 1px;
}

.table-bordered th, .table-bordered td {
  vertical-align: middle;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(84, 43, 102, 0.08);
}

.badge{
  padding: 5px 10px;
  border-radius: 3px;
}