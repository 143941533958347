.container.import-users {
  .instructions-title {
    margin-bottom: 16px;
  }


  ul.import-users-instructions {
    list-style: none;
    margin-left: -20px;
    margin-bottom: 16px;
    li {
      padding-top: 2px;
      padding-bottom: 4px;
      span {
        font-weight: bold;
        svg {
          margin-top: -5px
        }
      }
    }
  }

  button:disabled {
    opacity: 0.6;
  }

  label {
    margin-bottom: 0;
  }

  .import-buttons {
    margin-left: 16px;
    margin-bottom: 16x;
    display: flex;
    align-items: center;

    .btn {
      width: 200px;
      font-weight: bold;
    }

    a {
      margin-right: 10px;
      text-decoration: none;
    }

    @media(max-width:960px) {
      flex-direction: column;
      a {
        margin-right: 0;
        width: 100%;
        div {
          width: 100%;
        }
      }
      .btn {
        width: 100%;
      }
      label {
        width: 100%;
        div {
          width: 100%;
        }
      }
    }
  }
}
.buttonActionUser{
  display: flex;
  margin-top: 10px;
  a{ 
     margin-right: 10px;
  }
  svg{
    color: white;
    margin-right: 15px;
    margin-bottom: 5px;
  }  
}
td {
  border: 1px solid #dee2e6;

  padding: 0.75rem;
  text-align: left;
}
th{
  vertical-align: bottom;
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  text-align: left;  
  color: #212529;
}
@media screen and (max-width: 40em) { 
  .responsiveTable {
    display: flex;
    flex-direction: column;
    td.pivoted {
      padding-left: calc(40% + 10px) !important;
      text-align: right !important;
    }
    tbody tr {
      border: 1px solid #dee2e6;
      padding: 0.4em;
      text-align: right !important;
      background-color: white !important;
    }
    td .tdBefore{
      font-size: 13px;
     
      width: calc(60% - 20px);
    }
  }
  .buttonActionUser{
    display: grid;
    flex-wrap: initial;
    justify-content: normal;
    margin-left: 10px;
  }
}