.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }

  .Home {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: center center/cover no-repeat;
  }

  .report-item-text{
    font-size: 18px;
    text-align: center;
    color: white;
  }

  .home-container{
    width: 750px;
    height: 450px;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px gray;
  }

  .home-left-panel{
    flex: 8;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    padding: 5px;
  }

  .home-right-panel{
    flex: 11;
    background-color: white;
    padding: 10px 75px;
    display: flex;
    align-items: center;
  }

  .data-table-extensions-action{
    z-index: 1;
  }
  @media (max-width: 640px) 
  {
    .Home {
      margin:2%
    }
    .home-right-panel{
      padding: 10px 20px;
    }
  }

