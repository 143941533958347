.layout-manager-tablist {
    position: absolute;
    top: -43px;
    border-color: lightgray;
    border-bottom: none;
    padding: 0;
}

.layout-manager-tablist > li {
    font-size: 20px;
}

.section-manager-content-container{

}

.section-manager-content-list {
    display: flex;
    flex-wrap: wrap;
}

.section-manager-content-item {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 15px 10px;
    margin-right: 10px;
    width: 165px;
    min-height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-align: center;
    margin-bottom: 10px;
    position: relative;
    color: white;
}

.section-manager-content-item:hover:after{
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    background: inherit;
    background-color: rgba(255,255,255,0.15);
    transition: background-color ease 1s;
}

.section-manager-content-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-top: 10px;
}

.section-manager-content-item-type {
    margin-top: 0px;
    color: lightgray;
}

.section-manager-section-list {

}

.section-manager-section-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    height: 100%;
    color: gray;
}

.rst__moveHandle {
    background-color: #363740;
}

.section-manager-section-item {
    
}

.section-create-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.section-create-close:hover {
  transform: scale(1.2);
}

.section-create-form-container {
  display: flex;
  flex-direction: row;
}

.section-create-tree-container {
  margin-left: 15px;
  width: 400px;
  border: 1px solid rgba(0,0,0,0.25);
  border-radius: 5px;
  position: relative;
  padding-top: 15px;
}

.section-design-item-container {
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.25);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: #F7F8FC;
  transition: 0.3s;
  overflow: hidden;
}

.section-design-item-container:hover {
  background-color: #DDE2FF;
  transition: 0.3s;
}

.section-design-item-container:not(:last-child) {
  margin-bottom: 10px;
}

.section-design-item-container > div {
  width: 150px;
}

.section-design-header {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.section-design-header > div {
  width: 150px;
}

.section-create-tree-node {
  border: 1px solid black;
  padding: 10px;
  margin-top: 15px;
  height: 47px;
  cursor: pointer;
  background-color: #F7F8FC;
  border-radius: 5px;
}

div.section-create-tree-container > div > div:nth-child(1) > div > div > div > div.rst__nodeContent > div > div > div > div {
  background-color: #F7F8FC;
}

div.section-create-tree-container > div > div:nth-child(1) > div > div > div > div.rst__nodeContent > div > div > div:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px gray;
}

.section-design-tree-container {
  width: 100%;
  border-radius: 5px;
  position: relative;
  padding: 15px;
}

div.section-design-tree-container > div > div:nth-child(1) > div > div > div > div.rst__nodeContent > div > div > div > div {
  background-color: #F7F8FC;
}

div.section-design-tree-container > div > div:nth-child(1) > div > div > div > div.rst__nodeContent > div > div > div:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px gray;
}

.tree-item-selected {
  box-shadow: 0px 0px 15px #3751FF !important;
}

section-create-tree-node
.section-create-modal-container > div.MuiDialog-container.MuiDialog-scrollPaper > div {
  max-width: none !important;
}
div.MuiDialog-root.section-create-modal-container > div.MuiDialog-container.MuiDialog-scrollPaper > .MuiDialog-paperWidthSm {
  max-width: none !important;
}

.section-create-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 22px;
    padding: 10px;
    border-radius: 5px;
    color: white;
    margin-bottom: 30px;
}

.section-create-back {
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    padding-right: 5px;
    display: table;
    margin-bottom: 20px;
}

.design-manager-back-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 15px;
}

.design-manager-back-container:hover {
  color: gray;
}

.design-manager-error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: gray;
  font-size: 18px;
  margin: 30px 0px;
}

.design-manager-group-container {
  border: 1px solid rgba(0,0,0,0.25);
  width: 400px;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: rgba(54, 55, 64,0.07);
}

.design-manager-group-container > div:not(:last-child) {
 margin-bottom: 15px !important;
}

.design-manager-group-title {
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.design-manager-loading-status {
  position: absolute;
  left: -50px;
  height: 100%;
  display: flex;
  align-items: center;
}

.section-create-back:hover {
    background-color: rgba(0,0,0,0.05);
}

.section-create-container {
    padding: 15px;
}

.section-create-form {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.section-manager-content-title {
    font-size: 22px;
    margin-bottom: 15px;
}

.section-create-error {
    margin-bottom: 15px;
    color: indianred;
    text-align: center;
}

.section-create-image-container {
  border: 1px solid rgba(0,0,0,0.2);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.section-create-image-text {
  background-color: white;
  position: absolute;
  top: -12px;
  left: 10px;
  padding: 0px 5px;
  color: rgba(0,0,0,0.45);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
}

div.section-create-color-picker > div:nth-child(4) {
  position: absolute !important;
  padding-left: 75px;
  padding-top: 35px;
}

div.MuiDialog-root.section-create-modal-container > div.MuiDialog-container.MuiDialog-scrollPaper > div {
  overflow: inherit;
}

@media(max-width: 500px) {
  .section-create-form-container {
    flex-direction: column;
    align-items: center;
  }
  .section-create-tree-container {
    height: 450px;
    margin-left: 0px;
    width: 100%;
  }
  .section-create-form {
    width: 100%;
    margin-bottom: 15px;
  }

  div.MuiDialog-root.section-create-modal-container > div.MuiDialog-container.MuiDialog-scrollPaper > div > div {
    width: 90vw;
  }

  .design-manager-group-container {
    width: 100%;
  }

  .section-design-item-container > div:nth-child(4) {
    display: none;
  }

  .section-design-header > div:nth-child(4) {
    display: none;
  }

  .section-design-item-container > div {
    max-width: 33%;
  }

  .section-design-item-container > div:nth-child(1) {
    width: 100px;
  }
  
  .design-manager-loading-status {
    left: -40px;
  }
}